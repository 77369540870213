import { useEffect, useState } from "react"

const usePreloadImage = src => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const image = new Image()
    image.onload = () => {
      setLoaded(true)
    }
    image.src = src
  }, [src])

  return loaded
}

export default usePreloadImage
