import React from "react"
import { Play } from "react-feather"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  display: block;
  height: 12.5rem;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

const BlackText = styled.div`
  ${({ theme }) => `
    position: absolute;
    margin-top: auto;
    display: flex;
    padding: ${theme.spaces[0]};
    bottom: 0px;
    z-index: 2;
    background-color: rgba(0,0,0,0.8);
    color: white;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.rg};
    width: calc(100% - 2 * ${theme.spaces[0]});
  `}
`

const PlayButton = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
`

const Album = ({ text, children, playButton, onClick }) => (
  <Container onClick={onClick}>
    {children}
    {playButton && (
      <PlayButton>
        <Play color="white" size={150} />
      </PlayButton>
    )}
    <BlackText>{text}</BlackText>
  </Container>
)

export default Album
