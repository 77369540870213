import React from "react"
import HeroBackground from "../images/hero-background.jpg"
import styled from "styled-components"
import usePreloadImage from "../hooks/usePreloadImage"
import { motion } from "framer-motion"
import MarginContainer from "./MarginContainer"

const Container = styled.div`
  ${({ theme }) => `
    position: relative;
    background-color: ${theme.colors.blue};
    height: 25rem;
    width: 100%;
  `}
`

const HeroProper = styled(motion.div)`
  ${({ theme }) => `
    opacity: 0;
    background-image: url("${HeroBackground}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: inherit;
    width: calc(100% - ${theme.spaces[0]} * 2);
    padding-left: ${theme.spaces[0]};
    padding-right: ${theme.spaces[0]};
  `}
`

const heroVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const ShortHero = ({ children }) => {
  const loaded = usePreloadImage(HeroBackground)

  return (
    <Container>
      <HeroProper
        animate={loaded ? "visible" : "hidden"}
        variants={heroVariants}
      >
        {loaded && <MarginContainer>{children}</MarginContainer>}
      </HeroProper>
    </Container>
  )
}

export default ShortHero
