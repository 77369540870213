import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: inherit;
  align-items: center;
`

const Header = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.andellia};
    font-size: 2.5rem;
    color: ${theme.colors.blue};
    margin-top: ${theme.spaces[3]};

    @media (min-width: ${theme.breakpoints[0]}) {
      font-size: 4.5rem;
    }
  `}
`

const VioletBox = styled.div`
  ${({ theme }) => `
    position: relative;
    height: 0.25rem;
    background-color: ${theme.colors.blue};
    margin-left: ${theme.spaces[0]};
    top: 20px;

    
    @media (min-width: ${theme.breakpoints[0]}) {
      width: 10rem;
    }
  `}
`

const SectionHeader = ({ className, children, showLine }) => (
  <Container>
    <Header className={className}>{children}</Header>
    {showLine && <VioletBox />}
  </Container>
)

SectionHeader.defaultProps = {
  showLine: true,
}

export default SectionHeader
