import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import Album from "../../components/Album"
import FoldContainer from "../../components/FoldContainer"
import MarginContainer from "../../components/MarginContainer"
import SectionHeader from "../../components/SectionHeader"
import ShortHero from "../../components/ShortHero"

const ShortHeroText = styled.span`
  ${({ theme }) => `
    align-items: center;
    color: white;
    display: flex;
    font-family: ${theme.fonts.andellia};
    font-size: 3rem;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (min-width: ${theme.breakpoints[2]}){
      font-size: 6rem;
    }
  `}
`

const PictureGalleryContainer = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    margin-top: ${theme.spaces[0]};
  `}
`

const VideoContainer = styled(PictureGalleryContainer)`
  ${({ theme }) => `
    grid-template-columns: repeat(auto-fill, minmax(1fr, 2fr));
    margin-bottom: ${theme.spaces[1]};
  `}
`

const ThroughTheYearsPage = () => (
  <>
    <ShortHero>
      <ShortHeroText>Through The Years</ShortHeroText>
    </ShortHero>
    <FoldContainer>
      <MarginContainer>
        <SectionHeader>Picture Gallery</SectionHeader>
        <PictureGalleryContainer>
          <Album
            text="Learning Unit III"
            onClick={() => navigate("/through-the-years/lu3")}
          >
            <StaticImage
              src="../../images/lu3_preview.png"
              layout="fullWidth"
              alt="Learning Unit III"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="Learning Unit IV"
            onClick={() => navigate("/through-the-years/lu4")}
          >
            <StaticImage
              src="../../images/lu4_preview.jpg"
              layout="fullWidth"
              alt="Learning Unit IV"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="Learning Unit V"
            onClick={() => navigate("/through-the-years/lu5")}
          >
            <StaticImage
              src="../../images/lu5_preview.jpg"
              layout="fullWidth"
              alt="Learning Unit V"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="Learning Unit VI"
            onClick={() => navigate("/through-the-years/lu6")}
          >
            <StaticImage
              src="../../images/lu6_preview.jpg"
              layout="fullWidth"
              alt="Learning Unit VI"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="Learning Unit VII"
            onClick={() => navigate("/through-the-years/lu7")}
          >
            <StaticImage
              src="../../images/lu7_preview.jpg"
              layout="fullWidth"
              alt="Learning Unit VII"
              placeholder="blurred"
            />
          </Album>
        </PictureGalleryContainer>
      </MarginContainer>
    </FoldContainer>
    <FoldContainer>
      <MarginContainer>
        <SectionHeader>Videos</SectionHeader>
        <VideoContainer>
          <Album
            text="Mediscene"
            playButton={true}
            onClick={() => navigate("/through-the-years/mediscene")}
          >
            <StaticImage
              src="../../images/mediscene_preview.jpg"
              layout="fullWidth"
              alt="Mediscine"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="COME"
            playButton={true}
            onClick={() => navigate("/through-the-years/come")}
          >
            <StaticImage
              src="../../images/come_preview.png"
              layout="fullWidth"
              alt="COME"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="Tao Rin Pala"
            playButton={true}
            onClick={() => navigate("/through-the-years/tao-rin-pala")}
          >
            <StaticImage
              src="../../images/tao_rin_pala_preview.jpg"
              layout="fullWidth"
              alt="Tao Rin Pala"
              placeholder="blurred"
            />
          </Album>
          <Album
            text="UP Med Funked Me Up"
            playButton={true}
            onClick={() => navigate("/through-the-years/funked-me-up")}
          >
            <StaticImage
              src="../../images/funked_me_up_preview.png"
              layout="fullWidth"
              alt="Tao Rin Pala"
              placeholder="blurred"
            />
          </Album>
        </VideoContainer>
      </MarginContainer>
    </FoldContainer>
  </>
)

export default ThroughTheYearsPage
